// MODULES
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import * as jQuery from 'jquery';
import { DomSanitizer } from '@angular/platform-browser';
import { AutoComplete } from 'primeng/autocomplete';
import { Subscription, Subject } from 'rxjs';
// import userflow from 'userflow.js';

// UTILS
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { StatusConstant } from 'src/app/utils/status-constant';
import { MessageConstant } from 'src/app/utils/message-constant';
import { environment } from 'src/environments/environment';

// SERVICES
import { UserService } from 'src/app/providers/user/user.service';
import { LeadsService } from 'src/app/providers/leads/leads.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { SharedService } from 'src/app/shared/shared.service';
import { UpdateService } from 'src/app/update.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CreditService } from 'src/app/providers/credit/credit.service';
import { BillingService } from 'src/app/providers/billing/billing.service';

// COMPONENTS
import { IncomingCallDetailDailogsComponent } from '../../shared/dialog/incoming-call-detail-dailogs/incoming-call-detail-dailogs.component';
import { ToastrService } from 'ngx-toastr';
import { TaskService } from 'src/app/providers/task/task.service';

// COMPONENTS
import { AddEditTaskComponent } from 'src/app/modules/leads/leads-details/task/add-edit-task/add-edit-task.component';
import { SocketIoService } from '../../providers/socket-io/socket-io.service';
import { ChargeDialogComponent } from 'src/app/modules/billing/charge-dialog/charge-dialog.component';
import { TwilioService } from 'src/app/utils/twilio-service/twilio.service';
import { UpgradePlanDialogComponent } from 'src/app/shared/dialog/upgrade-plan-dialog/upgrade-plan-dialog.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GettingStartedUserProgressComponent } from 'src/app/shared/dialog/getting-started-user-progress/getting-started-user-progress.component';
import { NotificationComponent } from './notification/notification.component';
import { DlcStatusDailogComponent } from 'src/app/shared/dialog/dlc-status-dailog/dlc-status-dailog.component';
import { OnboardingCallDailogComponent } from 'src/app/shared/dialog/onboarding-call-dailog/onboarding-call-dailog.component';

declare var $: any;
declare var Plivo;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('autoComplete') autoComplete: AutoComplete;
  @ViewChild('reNotification') reNotification: NotificationComponent;
  @ViewChild('notificationDropdown') notificationDropdown: ElementRef;
  @ViewChild('notificationToggleBtn') notificationToggleBtn: ElementRef;
  searchForm: FormGroup;

  dialogRef: any;
  messageConstant = MessageConstant;
  moduleId: string = '601cf3c070419f19b74a6882';
  modules: any[] = [];
  actions: any[] = [];
  filteredBrands: any[];
  moduleList: any[] = [];
  userData: any;
  userImage: any = '';
  openModel: boolean = false;
  userList: any[] = [];
  userListing: any[] = [];
  currentUser: any = {};
  mainStatuses: any = [];
  subscriptionValidDate: any;
  subscriptionValid: any;
  coursePercent: any;

  isDirectMailFreeUser: boolean = false;
  dialogRefs: MatDialogRef<IncomingCallDetailDailogsComponent>;
  plivoBrowserSdk; //: Plivo;
  options = {
    debug: 'ALL',
    permOnClick: true,
    enableTracking: true,
    closeProtection: true,
    maxAverageBitrate: 48000,
    allowMultipleIncomingCalls: true,
  };

  countdown: string = '';
  countdownInterval: any;
  isShowUserProgress: boolean = false;
  totalSaving = '';
  membersCount: number = 0;
  remainingDays: number = 0;

  notificationCount: number = 0;
  constructor(
    public _utilities: CommonFunctionsService,
    private router: Router,
    private _router: ActivatedRoute,
    private _userService: UserService,
    private _leadService: LeadsService,
    public _sharedService: SharedService,
    private _sanitizer: DomSanitizer,
    private _updateService: UpdateService,
    private _creditService: CreditService,
    private _dialog: MatDialog,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _taskService: TaskService,
    private socketService: SocketIoService,
    private _billingService: BillingService,
    private _twilioService: TwilioService,
    private formBuilder: FormBuilder
  ) {
    this.searchForm = this.formBuilder.group({
      searchedItem: [''],
    });
    this._router.queryParams.subscribe((queryParams: any) => {
      if (queryParams.s != '') {
        this.searchForm.patchValue({
          searchedItem: { firstname: queryParams.s, AttributeValue: '' },
        });
      }
    });
    this._sharedService.refreshNotificationCount = new Subject();
    this._sharedService.refreshNotificationCount.subscribe((response) => {
      if (this.reNotification) {
        if (this.notificationCount != 0) this.notificationCount -= 1;
        this.getNotificationCount();
      }
    });
  }

  ngOnInit(): void {
    this.actions = [
      {
        label: '',
        alt: 'Hot Leads',
        icon: 'assets/images/hot-lead-new.svg',
        link: 'hotleads',
      },
      {
        label: '',
        alt: 'Whiteboard',
        icon: 'assets/images/whiteboard.svg',
        link: 'whiteboard',
      },
      {
        label: '',
        alt: 'Calendar',
        icon: 'assets/images/navbar/calendar-icon.svg',
        link: '/calendar',
      },
      {
        label: 'notification',
        alt: 'Notification',
        icon: 'assets/images/bell.svg',
      },
      // {
      //   label: '',
      //   alt: 'Settings',
      //   icon: 'assets/images/navbar/settings-gear.svg',
      // },
      {
        label: 'Balance',
        alt: 'Balance',
        icon: 'assets/images/navbar/sold-icon.svg',
        link: '/credit/buy-credit',
      },
    ];

    let token = localStorage.getItem('token');
    if (token) {
      this.getUserDetails();
      this.getUserPlan();
      this.getUserAccessData();
      this.getLoginUser();
      this.plivoBrowserSdk = new Plivo(this.options);
      // @ts-ignore
      window.plivoBrowserSdk = this.plivoBrowserSdk;
      this.getDLCForm();
      // if (environment.releaseStage != 'live') {
      this.getFailedChargeAmount();
      // }
      this.getUserProgress();
      this.getNotificationCount();
    }

    this.countdownInterval = setInterval(() => {
      this.updateCountdown();
    }, 1000);
  }

  setMenu() {
    let profileSubMenu = [];

    if (this.userData?.isSubUser && this.userData?.isManageUser) {
      profileSubMenu = [
        {
          id: 1,
          label: 'Profile',
          link: '/users/profile',
          icon: '/assets/images/navbar/profile-icons.svg',
        },
        {
          id: 4,
          label: 'Manage User',
          link: '/users/user-list',
          icon: '/assets/images/navbar/manage-users-icons.svg',
        },
      ];
    } else if (this.userData?.isSubUser) {
      profileSubMenu = [
        {
          id: 1,
          label: 'Profile',
          link: '/users/profile',
          icon: '/assets/images/navbar/profile-icons.svg',
        },
      ];
    } else {
      profileSubMenu = [
        {
          id: 1,
          label: 'Profile',
          link: '/users/profile',
          icon: '/assets/images/navbar/profile-icons.svg',
        },
        {
          id: 2,
          label: 'Billing',
          link: '/billing/list',
          icon: '/assets/images/navbar/billing-icons.svg',
        },
        {
          id: 3,
          label: 'Upgrade Plan',
          link: '/upgrade-plans',
          icon: '/assets/images/navbar/Upgrade-v1.svg',
        },
        {
          id: 4,
          label: 'D4$ Plans',
          link: '/upgrade-plans/driving-plans',
          icon: '/assets/images/navbar/d4dollar.svg',
        },
        {
          id: 5,
          label: 'Manage User',
          link: '/users/user-list',
          icon: '/assets/images/navbar/manage-users-icons.svg',
        },
        {
          id: 14,
          label: '10DLC Status',
          link: '/manage-numbers/dlc-number',
          icon: '/assets/images/navbar/10-dlc.svg',
        },
        // {
        //   id: 15,
        //   label: 'Cancel Account',
        //   externalLink: 'https://resimpli.com/cancellation-form/',
        //   icon: '/assets/images/navbar/billing-icons.svg',
        // },
      ];
    }

    if (this.isDirectMailFreeUser) {
      this.moduleList = [
        {
          label: 'Profile',
          submenu: [
            {
              id: 1,
              label: 'Profile',
              link: '/users/profile',
              icon: '/assets/images/navbar/profile-icons.svg',
            },
            {
              id: 2,
              label: 'Billing',
              link: '/billing/list',
              icon: '/assets/images/navbar/billing-icons.svg',
            },
          ],
        },
      ];
    } else {
      this.moduleList = [
        {
          label: 'Profile',
          submenu: profileSubMenu,
        },
        {
          label: 'Marketing',
          submenu: [
            {
              id: 11,
              label: 'Markets',
              link: '/markets',
              icon: '/assets/images/navbar/markets-icons.svg',
            },
            {
              id: 6,
              label: 'Manage Numbers',
              link: '/manage-numbers',
              icon: '/assets/images/navbar/manage-numbers-icons.svg',
            },
            {
              id: 7,
              label: 'Manage Templates',
              link: '/manage-templates',
              icon: '/assets/images/navbar/manage-templates.svg',
            },
            // {
            //   id: 7,
            //   label: 'Email Template',
            //   link: '/email-template',
            //   icon: '/assets/images/navbar/email-templates-icons.svg',
            // },
            // {
            //   id: 8,
            //   label: 'SMS Template',
            //   link: '/sms',
            //   icon: '/assets/images/navbar/sms-templates-icons.svg',
            // },
            // {
            //   id: 14,
            //   label: 'Task Template',
            //   link: '/task-template',
            //   icon: '/assets/images/navbar/task-template.svg',
            // },
            // {
            //   id: 9,
            //   label: 'RVM',
            //   link: '/rvm',
            //   icon: '/assets/images/navbar/rvm-icons.svg',
            // },
          ],
        },
        {
          label: 'Customization',
          submenu: [
            {
              id: 5,
              label: 'Custom Questions',
              link: '/preference',
              icon: '/assets/images/navbar/custom-questions-icons.svg',
            },
            {
              id: 10,
              label: 'Tags',
              link: '/tags',
              icon: '/assets/images/navbar/tags-icons.svg',
            },
            {
              id: 12,
              label: 'Call Script',
              link: '/dialer/script',
              icon: '/assets/images/navbar/Call-Script.svg',
            },
            {
              id: 13,
              label: 'Dialer Voicemail',
              link: '/dialer/voice-mail',
              icon: '/assets/images/navbar/Dialer-Voicemail.svg',
            },
            {
              id: 12,
              label: 'Webhook/API Token',
              link: '/webhook-and-api-token',
              icon: '/assets/images/navbar/api-token.svg',
            },
          ],
        },
      ];
    }

    if (this._updateService.promptEvent) {
      this.moduleList.push({
        label: 'Web App',
        submenu: [
          {
            id: 13,
            label: 'Install REsimpli',
            icon: 'assets/images/navbar/logo.svg',
          },
        ],
      });
    }

    this.modules = this.moduleList;
  }

  toggleHeaderClass() {
    jQuery('.navbar-fixed-top').toggleClass('top');
    jQuery('#wrapper').toggleClass('padd0');
  }

  logout() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    this._utilities.reset();
    localStorage.clear();
    this.router.navigate(['/auth/login']);
    // userflow.reset();
    if (this._utilities.isTwilioUser) {
      this._twilioService.deviceDestroy();
    } else {
      this.plivoBrowserSdk.client.logout();
    }

    this.socketService.disconnect();
  }

  getUserDetails() {
    this._userService.getUserDetails({}).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.userData = response?.data;
          // this.getCoursePercentage(this.userData?.teachableUserId);

          if (this.userData?.isSubUser) {
            this.modules = this.moduleList.filter(
              (x) => x.id !== 2 && x.id !== 3 && x.id !== 4
            );
          } else {
            this.membersCount = this.userData?.membersCount;
            this.modules = this.moduleList;
          }
          if (this.userData.isDirectMailFreeUser == true) {
            this.isDirectMailFreeUser = true;
            localStorage.setItem(
              'isDirectMailFreeUser',
              this.userData.isDirectMailFreeUser
            );
          }
          this.setMenu();
          if (this.userData?.profileImage) {
            this.userImage = this.userData?.profileImage;
          }
          this._utilities.userData = this.userData;
          this.setupIntercom();
          // this.setupUserFlow();
        }
      },
      (err: ErrorModel) => {}
    );
  }

  filterBrands(event) {
    this.filteredBrands = [''];

    const obj = {
      searchString: this.searchForm.value.searchedItem
        ?.replaceAll('(', '')
        ?.replaceAll(')', '')
        ?.trim(),
    };
    this._leadService.searchleads(obj).subscribe(
      (response: ResponseModel) => {
        this.filteredBrands = response.data.items;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  getCoursePercentage(teachableUserId: any) {
    this._userService.getCoursePercentage({}).subscribe(
      (response: ResponseModel) => {
        let subscriptionData = response.data;
        this.subscriptionValidDate = subscriptionData?.subscriptionValidDate;
        this.subscriptionValid = subscriptionData?.subscriptionValid;
        this.coursePercent = subscriptionData?.courseDetail?.percent_complete;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  goTo(item) {
    if (typeof item === 'object') {
      this.goToDetails(item);
    } else {
      if (!item?.trim()) {
        return;
      }

      this.router.navigate([
        '/leads/search-leads/',
        item.replaceAll('(', '').replaceAll(')', ''),
      ]);
      this.filteredBrands = [];
    }
  }

  goToPage() {
    if (typeof this.searchForm.value.searchedItem === 'object') {
      this.goToDetails(this.searchForm.value.searchedItem);
      return;
    }

    const queryParams = {
      s: this.searchForm.value.searchedItem,
    };
    this.autoComplete.hide();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['global-search/'], { queryParams }));
  }

  goToDetails({ _id, mainStatusId }) {
    if (!_id) {
      return;
    }
    const obj = { leadid: _id };
    localStorage.setItem('toplead', 'true');
    localStorage.setItem('topappoint', 'true');

    if (
      mainStatusId === '5feb3f94aa810a3849fa5515' ||
      mainStatusId === '5feb4221aa810a3849fa551b' ||
      mainStatusId === '5feb4252aa810a3849fa551c' ||
      mainStatusId === '60221e37d8562ef0219c072a'
    ) {
      this._leadService.getProjectWithLeadId(obj).subscribe(
        (response: ResponseModel) => {
          if (!response.data?.items) {
            this.router.navigate([`/inventory`]);
            return;
          }

          const { mainStatusId, _id } = response.data?.items;
          if (
            mainStatusId == '60225879d8562ef021ca2fcd' ||
            mainStatusId == '5feb4221aa810a3849fa551b'
          ) {
            this.router.navigate([`/sold/details`], {
              queryParams: { ['soldId']: _id },
            });
          } else if (
            mainStatusId == '60225867d8562ef021ca2180' ||
            mainStatusId == '5feb4252aa810a3849fa551c'
          ) {
            this.router.navigate([`/rental/details`], {
              queryParams: { ['rentalId']: _id },
            });
          } else {
            this.router.navigate([`/inventory/details`], {
              queryParams: { ['inventoryId']: _id },
            });
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    } else {
      const key = 'leadsId';
      this.router.navigate([`/leads/details`], {
        queryParams: { [key]: _id },
      });
    }
  }

  getFile(src) {
    let param = {
      moduleId: this.moduleId,
      src: src,
    };

    this._sharedService.getFile(param).subscribe(
      (response) => {
        if (response) {
          let objectURL = URL.createObjectURL(response);
          this.userImage = this._sanitizer.bypassSecurityTrustUrl(objectURL);
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
        } else {
        }
      }
    );
  }

  closePopOver(menu?) {
    $('.popover_close').parents('.popover').popover('hide');
    $('body .infinite-scroll').removeClass('re-scroll-hide');
    $('body .re-card').removeClass('re-open-card');

    if (!menu) return;

    if (menu?.link) {
      this.router.navigate([menu?.link], {
        queryParams: menu?.queryParam,
      });
    } else if (menu?.externalLink) {
      window.open(menu?.externalLink, '_blank');
    } else {
      this._updateService.promptEvent.prompt();
    }
  }

  reload() {
    this._utilities.updateAvailable = false;
    document.location.reload();
  }

  setupIntercom() {
    (<any>window).intercomSettings = {
      app_id: 'ysyhk578',
      name: this.userData?.firstName + ' ' + this.userData?.lastName,
      email: this.userData?.email, // Email address
      created_at: '1633694189000', // Signup date as a Unix timestamp
      custom_launcher_selector: '#custom_link_intercom',
      hide_default_launcher: true,
    };
    (<any>window).Intercom('update', (<any>window).intercomSettings);
  }

  getUserPlan() {
    this._creditService.getUserPlan({}).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._utilities.currentPlanData = response.data;

          this.getRemainingDays(response.data?.renewCycleDate);
          let planPrice = Number(
            this._utilities.currentPlanData?.price / 100
          ).toString();
          let offerPrice = Number(
            this._utilities.currentPlanData?.upgradeYearPrice / 100
          ).toString();

          let totalSaving = (Number(planPrice) - Number(offerPrice)) * 12;
          this.totalSaving = totalSaving.toFixed(2);
        }
      },
      (err: ErrorModel) => {}
    );
  }

  getUserAccessData() {
    this._userService.getUserAccessData({}).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._utilities.userAccessData = response?.data;
        }
      },
      (err: ErrorModel) => {}
    );
  }

  // setupUserFlow() {
  //   userflow.init('ct_ala6gujttrez5bl6dl42tfbqcy');
  //   userflow.identify(this.userData?._id, {
  //     name: this.userData?.firstName + ' ' + this.userData?.lastName,
  //     email: this.userData?.email,
  //     signed_up_at: new Date().toISOString(),
  //   });
  //   userflow.setResourceCenterLauncherHidden(true);
  // }
  async onTaskDialog(event, i?) {
    await this.getMainStatusList();
    this.dialogRef = this._dialog.open(AddEditTaskComponent, {
      width: '550px',
      data: {
        isGetTeams: false,
        type: 'task',
        subType: 'task',
        category: event,
        id: i,
        userList: this.userList,
        currentUser: this.currentUser,
        isGeneralTask: true,
        isDisable: false,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._loaderService.start();
        result.title = result.title.trim();
        result.taskDetail = result.taskDetail ? result.taskDetail.trim() : '';
        result.moduleId = this.mainStatuses.find(
          (item) => item._id == result.mainStatusId
        ).moduleId;
        result.mainStatusId = result.mainStatusId;
        if (!result.isGeneralTask) {
          result.subModuleId = result.subModuleId;
        } else {
          result.subModuleId =
            StatusConstant.GeneralModule.GENERAL_SUB_MODULE_ID;
          result.roleId = null;
        }
        result.taskType = result.taskType;
        this._taskService.createTask(result).subscribe(
          (response: ResponseModel) => {
            if (response.statusCode == 200) {
              this._loaderService.stop();
              this._toastrService.success(
                this.messageConstant.taskAddedSuccess,
                ''
              );
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      }
    });
  }
  getUsers(loginUserId, loginUserName) {
    this._userService.getUsers({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.userList = [];
          this.userListing = response?.data;
          for (let i = 0; i < response?.data.length; i++) {
            const user = response?.data[i];
            const fullName = user.firstName + ' ' + user.lastName;
            const matches = fullName.match(/\b(\w)/g);
            const acronym = matches?.join('');
            this.userList.push({
              label: user.firstName + ' ' + user.lastName,
              value: user._id,
              profileImage: user?.profileImage ? user?.profileImage : '',
              name: acronym || 'N/A',
            });
          }
          let filterArray = this.userList?.filter((x) => {
            return x.value == this._utilities.dashboardSelectedUserId;
          });
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  getLoginUser = () => {
    this._userService.getUserDetails({}).subscribe(
      (response: ResponseModel) => {
        this.currentUser = response.data;
        let loginUserId = response?.data?._id;
        let loginUserName =
          response?.data?.firstName + ' ' + response?.data?.lastName;

        if (response.data?.loginData) {
          localStorage.setItem(
            'currentUser',
            JSON.stringify({ ...response.data?.loginData })
          );
        }
        this.getUsers(loginUserId, loginUserName);
      },
      (err: ErrorModel) => {
        this.getUsers('', '');
      }
    );
  };
  getMainStatusList() {
    return new Promise((resolve, reject) => {
      this._sharedService.getMainStatus({ page: 1, limit: 100 }).subscribe(
        (response: ResponseModel) => {
          this.mainStatuses = response.data.items;
          return resolve(this.mainStatuses);
        },
        (err: ErrorModel) => {
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
          return reject([]);
        }
      );
    });
  }

  getDLCForm() {
    this._sharedService.getDLCForm({}).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          this._utilities.dlcForm = response?.data;
          if (environment.releaseStage != 'live') {
            this._utilities.dlcForm = {
              status: 'completed',
            };
          }
        }
      },
      (err: ErrorModel) => {}
    );
  }

  moveToHotLead() {
    this._utilities.isFilterLeads = true;

    let obj = {
      deadLeadStatus: 'true',
      warmLeadStatus: 'true',
      referredToAgentStatus: 'true',
      hotLead: 'include',
    };
    this._utilities.leadFilter = obj;
    this.router.navigate(['/leads'], {
      queryParams: {
        ['hotlead']: 'true',
      },
    });
  }

  moveToWhiteboard() {
    this._utilities.isFilterLeads = true;

    let obj = {};
    this._utilities.leadFilter = obj;
    this.router.navigate([`/leads`], {
      queryParams: {
        ['leadType']: 'whiteboard',
      },
    });
  }

  getFailedChargeAmount() {
    this._billingService.getFailedChargeAmount({}, true).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          this._utilities.chargeSummary = response?.data;
          if (this._utilities.chargeSummary) {
            this.onFailedCharge(false);
          }
        }
      },
      (err: ErrorModel) => {}
    );
  }

  onFailedCharge(model: boolean) {
    this.dialogRef = this._dialog.open(ChargeDialogComponent, {
      width: '650px',
      data: { isModelFor: model },
    });
  }

  isShowUpgradeOffer() {
    if (
      this._utilities.userData?.loginData &&
      !this._utilities.userData?.isSubUser &&
      !this._utilities.currentPlanData?.isYearlyPlan &&
      this._utilities.userData?.loginData?.isShowMemorialUpgradeOffer
    ) {
      return true;
    }
    return false;
  }

  openUpgradePlanInfo() {
    if (
      !this._utilities.userData?.isSubUser &&
      !this._utilities.currentPlanData?.isYearlyPlan &&
      this._utilities.userData?.loginData?.isShowMemorialUpgradeOffer
    ) {
      this.dialogRef = this._dialog.open(UpgradePlanDialogComponent, {
        disableClose: true,
        panelClass: 're-upgrade-plan-modal',
        width: '550px',
        data: {
          action: 'upgradeOfferAction',
        },
      });

      this.dialogRef.afterClosed().subscribe((result) => {
        if (result == 'Yes') {
          this._utilities.isFromUpgradePopup = true;
          this.router.navigate(['/upgrade-plans']);
        }
      });
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.countdownInterval);
  }

  getNotificationCount(data?) {
    let obj = {
      page: 1,
      limit: 10,
      activityType: 0,
    };

    this._sharedService.getAllNotifications(obj).subscribe(
      (response) => {
        if (response?.statusCode == 200) {
          this.notificationCount = response?.data?.unreadCount || 0;

          if (data?.redirectUrl) {
            this.router.navigateByUrl(data?.redirectUrl);
            this.notificationToggleBtn.nativeElement.click();
          }
        }
      },
      (err) => {}
    );
  }

  emittedFromNotification($event) {
    if ($event?.count) {
      this.notificationCount = $event?.count || 0;
      return;
    }
    this.getNotificationCount($event);
    // if ($event) {
    //   if ($event?.count) {
    //     this.notificationCount = $event?.count || 0;
    //   }
    //   return;
    // }
    //this._sharedService.refreshNotificationCount.next(true);

    //this.notificationToggleBtn.nativeElement.click();
  }

  callNotification() {
    // Toggle the dropdown state
    const dropdownElement: HTMLElement =
      this.notificationDropdown.nativeElement;

    // Check if the classList contains 'show'
    const hasShowClass = dropdownElement.classList.contains('show');

    if (!hasShowClass && this.reNotification) {
      this.reNotification.tab = 'all';
      this.reNotification.currentNotificationPage = 1;
      this.reNotification.allNotifications['count'] = 0;
      this.reNotification.allNotifications['items'] = [];
      this.reNotification.initialize();
    }
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  private updateCountdown() {
    // Get the current date and the end of the year
    const currentDate = new Date();
    const endOfYear = new Date(1725494400000);

    if (currentDate > endOfYear) {
      this.countdown = `00D : 00H : 00M : 00S`;
      clearInterval(this.countdownInterval);
      return;
    }

    // Calculate the time difference in milliseconds
    const timeDifference = endOfYear.getTime() - currentDate.getTime();

    // Convert milliseconds to days, hours, minutes, and seconds
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    this.countdown = `${days}D : ${this.formatTime(hours)}H : ${this.formatTime(
      minutes
    )}M : ${this.formatTime(seconds)}S`;
  }

  setGlobalSearch(value) {
    this.searchForm.patchValue({
      searchedItem: value,
    });
  }

  private formatTime(time: number): string {
    return time < 10 ? `0${time}` : `${time}`;
  }

  openGettingModal() {
    this.getUserProgress(true);
  }
  getUserProgress(isOpenPopup = false) {
    if (isOpenPopup) {
      this._loaderService.start();
    }

    this._userService.getUserProgress({}).subscribe(
      (response: ResponseModel) => {
        if (isOpenPopup) {
          this._loaderService.stop();
        }

        this.getUserProgressUpdated(response, null, isOpenPopup);

        this._userService.getUserProgressListStacking({}).subscribe(
          (lisStackingResponse: ResponseModel) => {
            this.getUserProgressUpdated(response, lisStackingResponse, false);
          },
          (err: ErrorModel) => {}
        );
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getUserProgressUpdated(response, lisStackingResponse, isOpenPopup) {
    let progressData = response?.data?.data;
    let progressData1 = response?.data?.data;

    if (lisStackingResponse?.data?.data) {
      this._utilities.progressListStackingData =
        lisStackingResponse?.data?.data;
      if (this._utilities.progressListStackingData?.length > 1) {
        progressData?.map((item) => {
          if (item?.title == 'Data') {
            let valueArray = [];
            let listStackingObj = this._utilities.progressListStackingData[0];
            valueArray.push(listStackingObj);

            if (item?.value?.length > 0) {
              valueArray.push(item?.value[0]);
            }

            let skipTracingObj = this._utilities.progressListStackingData[1];
            valueArray.push(skipTracingObj);
            item.value = valueArray;
          }
        });
      }
    }

    let totalProgress = 0;
    let userProgress = 0;

    for (let i = 0; i < progressData.length; i++) {
      const obj = progressData[i];
      for (let j = 0; j < obj?.value?.length; j++) {
        const subObj = obj?.value[j];
        totalProgress = totalProgress + 1;
        if (subObj?.value) {
          userProgress = userProgress + 1;
        }
      }
    }
    this._utilities.userProgressPercentage = Math.round(
      (userProgress * 100) / totalProgress
    );

    if (this._utilities.userProgressPercentage >= 100) {
      this.isShowUserProgress = false;
    } else {
      this.isShowUserProgress = true;
    }

    this._utilities.progressPercentage =
      this._utilities.userProgressPercentage + ',100';

    if (isOpenPopup) {
      this.dialogRef = this._dialog.open(GettingStartedUserProgressComponent, {
        width: '600px',
        data: {
          progressData: progressData1,
        },
      });
    }
  }

  isShowUpgradeOption() {
    if (
      !this._utilities.userData?.isSubUser &&
      !this._utilities.currentPlanData?.isYearlyPlan
    ) {
      return true;
    }

    return false;
  }

  moveToUpgrade() {
    this._utilities.isFromUpgradePopup = true;
    this.router.navigate(['/upgrade-plans']);
  }

  dlcStatusModal() {
    this.dialogRef = this._dialog.open(DlcStatusDailogComponent, {
      disableClose: true,
      panelClass: 're-dlc-status-modal',
      width: '720px',
      data: {},
    });
  }

  getRemainingDays(renewCycleDate: any) {
    const currentDate = new Date().getTime();
    const remainingTime = renewCycleDate - currentDate;
    const remainingDays = Math.ceil(remainingTime / (1000 * 3600 * 24)); // Convert milliseconds to days

    this.remainingDays = Math.max(remainingDays, 0); // Ensure the result is not negative
  }

  onboardingDialog() {
    this.dialogRef = this._dialog.open(OnboardingCallDailogComponent, {
      // disableClose: true,
      panelClass: 're-onboarding-modal',
      width: '1000px',
      data: {},
    });

    this.dialogRef.afterClosed().subscribe((result) => {});
  }
}
