import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { MessageConstant } from 'src/app/utils/message-constant';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

import { UserService } from 'src/app/providers/user/user.service';
@Component({
  selector: 'app-upgrade-plan-dialog',
  templateUrl: './upgrade-plan-dialog.component.html',
  styleUrls: ['./upgrade-plan-dialog.component.scss'],
})
export class UpgradePlanDialogComponent implements OnInit, OnDestroy {
  messageConstant = MessageConstant;

  percentage = '40';
  planName = '';
  planPrice = '';
  offerPrice = '';
  totalSaving = '';
  countdownInterval: any;
  days: string = '';
  hours: string = '';
  minutes: string = '';
  seconds: string = '';

  constructor(
    private _dailog: MatDialogRef<UpgradePlanDialogComponent>,
    private _userService: UserService,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    public _utilities: CommonFunctionsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.isBasicPlan()) {
      this.percentage = '40';
    }
    this.updatePrice();
  }

  ngOnInit() {
    this.updateCountdown();

    // Update the countdown every second
    this.countdownInterval = setInterval(() => {
      this.updateCountdown();
    }, 1000);
  }

  ngOnDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.countdownInterval);
  }

  private updateCountdown() {
    // Get the current date and the end of the year
    const currentDate = new Date();
    const endOfYear = new Date(1725494400000);

    if (currentDate > endOfYear) {
      this.days = `00`;
      this.hours = `00`;
      this.minutes = `00`;
      this.seconds = `00`;
      clearInterval(this.countdownInterval);
      return;
    }

    // Calculate the time difference in milliseconds
    const timeDifference = endOfYear.getTime() - currentDate.getTime();

    // Convert milliseconds to days, hours, minutes, and seconds
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    this.days = `${days}`;
    this.hours = `${this.formatTime(hours)}`;
    this.minutes = `${this.formatTime(minutes)}`;
    this.seconds = `${this.formatTime(seconds)}`;
  }

  private formatTime(time: number): string {
    return time < 10 ? `0${time}` : `${time}`;
  }

  onYes() {
    this.saveUpgradeAction('Yes');
  }

  onNo() {
    this.saveUpgradeAction('No');
  }

  saveUpgradeAction(flag) {
    this._loaderService.start();
    this._userService
      .saveUpgradeAction({ action: flag, actionType: this.data?.action })
      .subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          this._dailog.close(flag);
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
  }

  isBasicPlan() {
    if (
      this._utilities.currentPlanData?._id == '60d44e0cd8460702deea3932' ||
      this._utilities.currentPlanData?._id == '5cda93957074b715e4d21919'
    ) {
      return true;
    }
    return false;
  }

  updatePrice() {
    this.planName = this._utilities.currentPlanData?.planName;
    this.planPrice = Number(
      this._utilities.currentPlanData?.price / 100
    ).toString();
    this.offerPrice = Number(
      this._utilities.currentPlanData?.upgradeYearPrice / 100
    ).toString();

    let totalSaving = (Number(this.planPrice) - Number(this.offerPrice)) * 12;
    this.totalSaving = totalSaving.toFixed(2);
  }
}
