<header>
  <!-- NEW UPDATE AVAILABLE -->
  <div *ngIf="_utilities.updateAvailable" class="re-update-available">
    <span> A new version of REsimpli available </span>

    <button type="submit" mat-flat-button class="re-refresh" (click)="reload()">
      REFRESH
    </button>
  </div>

  <nav class="re-navbar navbar-default navbar-fixed-top" role="navigation">
    <div class="navbar-header">
      <button
        type="button"
        class="navbar-toggle"
        data-toggle="collapse"
        data-target="#navbar-collapse"
      >
        <span class="sr-only">Toggle navigation</span>
        <span class="fa fa-bars"></span>
      </button>
      <a
        class="navbar-brand"
        href="javascript:;"
        routerLink="/dashboard"
        *ngIf="isDirectMailFreeUser == false"
      >
        <img src="assets/images/navbar/logo.svg" alt="logo" />
      </a>
      <a
        class="navbar-brand"
        *ngIf="isDirectMailFreeUser"
        style="cursor: pointer"
      >
        <img src="assets/images/navbar/logo.svg" alt="logo" />
      </a>
    </div>
    <div id="navbar-collapse" class="collapse navbar-collapse re-txt-center">
      <div class="re-dlc-message" *ngIf="!_utilities.isTwilioUser">
        <img class="img" src="assets/images/error-triangle.svg" alt="error" />
        Your outgoing
        <span class="text-style-1">SMS will be impacted</span>. Please
        <span class="text-style-2" (click)="dlcStatusModal()">Take Action</span>
        ASAP.
      </div>
      <div
        class="utilizing"
        (click)="openGettingModal()"
        *ngIf="isShowUserProgress && _utilities.isTwilioUser"
      >
        <div class="chart" data-percent="60">
          <svg viewBox="0 0 36 36" class="circle">
            <path
              class="circle2"
              stroke-dasharray="100, 100"
              d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            ></path>
            <path
              class="circle1"
              [attr.stroke-dasharray]="_utilities.progressPercentage"
              d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            ></path>
          </svg>
          <div class="re-chartdiv">
            <span id="count" class="count">{{
              _utilities.userProgressPercentage
            }}</span>
            <span class="count">%</span>
          </div>
        </div>
        <p class="text">
          <span>Features activated</span>
        </p>
      </div>
      <a
        class="re-suggest-btn"
        target="_blank"
        routerLink="/manage-numbers/dlc-number"
        *ngIf="
          !userData?.isSubUser && _utilities.dlcForm?.status != 'completed'
        "
        >10DLC Reg.</a
      >
      <a
        class="re-suggest-fail-btn"
        *ngIf="_utilities.chargeSummary?.total > 0"
        (click)="onFailedCharge(true)"
        >Failed Charge</a
      >
      <!-- <div class="re-onbording-sec" *ngIf="subscriptionValid">
        <div class="re-progressmain">
          <div class="re-progre">
            <span class="re-txt">Onboarding</span>
            <div class="re-indicat">
              <div
                class="re-countt"
                [ngStyle]="{ width: coursePercent + '%' }"
              ></div>
            </div>
            <span class="re-txt">{{ coursePercent }}%</span>
          </div>
          <a
            class="re-btnb"
            target="_blank"
            href="https://course.resimpli.com/courses/enrolled/1935020"
            ><i class="fa fa-play" aria-hidden="true"></i> Getting Started</a
          >
        </div>
        <div class="re-txtinfo">
          Complete by {{ subscriptionValidDate | date: "MMM dd, yyyy" }} and get
          <span>$50 off</span> on next bill.
        </div>
      </div> -->
      <!-- GLOBAL SEARCH -->
      <form
        class="navbar-form"
        role="search"
        *ngIf="isDirectMailFreeUser == false"
      >
        <div class="input-group" [formGroup]="searchForm">
          <span class="input-group-addon"
            ><span class="fa fa-search"></span
          ></span>
          <p-autoComplete
            #autoComplete
            formControlName="searchedItem"
            [suggestions]="filteredBrands"
            name="item"
            field="firstname"
            (completeMethod)="filterBrands($event)"
            [size]="30"
            [minLength]="2"
            placeholder="Type anything and hit enter"
            (keyup.enter)="goToPage()"
            (click)="closePopOver()"
          >
            <ng-template let-brand pTemplate="item">
              <div class="ui-helper-clearfix">
                <div class="re-searchname" (click)="goToDetails(brand)">
                  {{ brand?.name ? brand?.name : brand?.title }}
                  {{
                    brand?.number
                      ? _utilities.maskNumber(brand?.number)
                      : _utilities.maskNumber(brand?.phoneNumber)
                  }}
                  <br />
                  <br />
                  {{ _utilities.formatAddress(brand?.address, brand?.unitNo) }}
                </div>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
      </form>
      <!-- ACTIONS -->
      <ul class="nav re-navbar-nav navbar-right">
        <li
          *ngIf="
            !_utilities?.userData?.loginData?.isOnBoardingCallScheduled &&
            _utilities?.userData?.loginData?.isTrialPeriod
          "
        >
          <a class="re-onboarding-btn" (click)="onboardingDialog()"
            >Book a Free Onboarding Call</a
          >
        </li>
        <li *ngIf="isShowUpgradeOffer()">
          <a class="re-upgrade-offer" (click)="openUpgradePlanInfo()">
            Labor Day Offer expires in {{ countdown }}
          </a>
        </li>
        <li class="re-addlead-head">
          <div class="dropdown">
            <a class="dropdown-toggle" data-toggle="dropdown">
              <i class="fa fa-plus"></i> Add
            </a>
            <div class="dropdown-menu dropdown-block" role="menu">
              <a class="re-links" href="javascript:;" routerLink="/leads/add"
                >Lead</a
              >
              <a
                class="re-links"
                href="javascript:void(0);"
                (click)="onTaskDialog('Add')"
                >Task</a
              >
            </div>
          </div>
        </li>
        <ng-container *ngIf="isDirectMailFreeUser == false">
          <ng-container *ngFor="let action of actions">
            <li class="re-actions" *ngIf="action?.link == 'hotleads'">
              <a class="btn" (click)="moveToHotLead()">
                <img class="img" [src]="action?.icon" [alt]="action?.alt" />
                <span *ngIf="action?.label" class="btntext">
                  {{ action?.label }}
                </span>
              </a>
            </li>
            <li class="re-actions" *ngIf="action?.link == 'whiteboard'">
              <a class="btn" (click)="moveToWhiteboard()">
                <img class="img" [src]="action?.icon" [alt]="action?.alt" />
                <span *ngIf="action?.label" class="btntext">
                  {{ action?.label }}
                </span>
              </a>
            </li>
            <li
              class="re-actions"
              *ngIf="
                action?.link != 'hotleads' &&
                action?.link != 'whiteboard' &&
                action.label != 'notification'
              "
            >
              <a class="btn" [routerLink]="[action?.link]">
                <img class="img" [src]="action?.icon" [alt]="action?.alt" />
                <span *ngIf="action?.label" class="btntext">
                  {{ action?.label }}
                </span>
              </a>
            </li>
            <li
              class="re-actions dropdown re-notification"
              *ngIf="action?.label == 'notification'"
            >
              <a
                class="btn dropdown-toggle"
                data-toggle="dropdown"
                (click)="callNotification()"
                style="background-color: #e5e5e5 !important"
                #notificationToggleBtn
              >
                <img class="img" [src]="action?.icon" [alt]="action?.alt" />
                <span
                  [ngClass]="
                    notificationCount > 9
                      ? 're-notification-count re-notification-count-more'
                      : 're-notification-count'
                  "
                  *ngIf="notificationCount > 0"
                  >{{
                    notificationCount > 9
                      ? "9+"
                      : notificationCount
                      ? notificationCount
                      : 0
                  }}</span
                >
              </a>
              <div
                class="dropdown-menu dropdown-block"
                role="menu"
                (click)="stopPropagation($event)"
                #notificationDropdown
              >
                <app-notification
                  #reNotification
                  (_emitterFunction)="emittedFromNotification($event)"
                ></app-notification>
              </div>
            </li>
          </ng-container>
        </ng-container>
        <!-- MODULES -->
        <li class="dropdown re-profile-drop">
          <a id="user-profile" class="dropdown-toggle" data-toggle="dropdown">
            <!-- USER PROFILE IMAGE -->
            <img
              class="img-responsive img-thumbnail img-circle"
              [src]="userImage"
              alt="profile"
              *ngIf="userImage"
            />

            <!-- DEFAULT IMAGE -->
            <img
              src="/assets/images/demo-profile.png"
              class="img-responsive img-thumbnail img-circle"
              alt="profile"
              *ngIf="!userImage"
            />
          </a>
          <div class="dropdown-menu" role="menu">
            <div class="re-userinfo-div">
              <div class="re-userinfo-sub">
                <img
                  class="img-circle"
                  [src]="userImage"
                  alt="profile"
                  *ngIf="userImage"
                />
                <img
                  src="/assets/images/demo-profile.png"
                  class="img-circle"
                  alt="profile"
                  *ngIf="!userImage"
                />
                <p class="re-txt1">
                  {{ userData?.firstName }} {{ userData?.lastName }}
                </p>
                <p class="re-txt2">{{ userData?.email }}</p>
              </div>
            </div>
            <div class="re-menu-div">
              <a class="re-mlink" [routerLink]="['/users/profile']"
                ><img
                  alt="icons"
                  class="img"
                  src="/assets/images/rightbar-profile.svg"
                />
                Profile
              </a>
              <a
                class="re-mlink"
                [routerLink]="['/users/notification-preference']"
                ><img alt="icons" class="img" src="/assets/images/bell.svg" />
                Notification
              </a>
              <a class="re-mlink" [routerLink]="['billing/list']" *ngIf="!userData?.isSubUser"
                ><img
                  alt="icons"
                  class="img"
                  src="/assets/images/rightbar-billing-details.svg"
                />
                Billing Details
              </a>
              <a class="re-mlink" [routerLink]="['/upgrade-plans']" *ngIf="!userData?.isSubUser"
                ><img
                  alt="icons"
                  class="img"
                  src="/assets/images/rightbar-upgrade-plan.svg"
                />
                Upgrade Plan
              </a>
              <a
                class="re-mlink"
                *ngIf="!userData?.isSubUser"
                [routerLink]="['/upgrade-plans/driving-plans']"
                ><img
                  alt="icons"
                  class="img"
                  src="/assets/images/navbar/d4dollar.svg"
                />
                D4$ Plans
              </a>
            </div>
            <div class="re-plan-div" *ngIf="!userData?.isSubUser">
              <div class="re-plan-txt1">
                {{ _utilities.currentPlanData?.planName }} Plan •
                <span>{{
                  _utilities.currentPlanData?.isYearlyPlan
                    ? "Yearly"
                    : "Monthly"
                }}</span>
              </div>
              <div class="re-plan-txt2">
                {{ membersCount }} Members / {{ remainingDays }} Days left
              </div>
              <button
                type="button"
                class="re-upgade-btn"
                *ngIf="isShowUpgradeOption()"
                (click)="moveToUpgrade()"
              >
                <div class="re-txt1">Save ${{ totalSaving }}</div>
                <div class="re-txt2">Switch to Yearly</div>
                <img
                  src="/assets/images/rightbar-up-arrow.svg"
                  class="img"
                  alt="up-arrow"
                />
              </button>
            </div>
            <div class="re-logout-div">
              <a class="re-mlink" (click)="logout()"
                ><img
                  src="/assets/images/rightbar-logout.svg"
                  class="img"
                  alt="icons"
                />Logout</a
              >
            </div>
          </div>
          <!-- <ul class="dropdown-menu dropdown-block" role="menu">
            <li>
              <div class="re-profile-div">
                <img
                  class="img-circle"
                  [src]="userImage"
                  alt="profile"
                  *ngIf="userImage"
                />
                <div class="re-user-name">
                  {{ userData?.firstName }} {{ userData?.lastName }}
                </div>
                <button
                  type="button"
                  class="re-upgade-btn"
                  *ngIf="isShowUpgradeOption()"
                  (click)="moveToUpgrade()"
                >
                  <img src="assets/images/star-circle.svg" alt="star" />
                  <div>
                    <div class="re-txt1">Save ${{ totalSaving }}</div>
                    <div class="re-txt2">Upgrade to Yearly</div>
                  </div>
                </button>
              </div>
            </li>
            <ng-container *ngFor="let module of modules">
              <li>
                <span class="re-labels">
                  {{ module?.label }}
                </span>
              </li>
              <li *ngFor="let menu of module.submenu">
                <a (click)="closePopOver(menu)">
                  <img [src]="menu.icon" class="img" alt="icons" />
                  {{ menu?.label }}
                </a>
              </li>
            </ng-container>

            <li>
              <a (click)="logout()" class="re-logout"
                ><img
                  src="/assets/images/navbar/logout-icons.svg"
                  class="img"
                  alt="icons"
                />Logout</a
              >
            </li>
          </ul> -->
        </li>
      </ul>
    </div>
  </nav>
</header>
