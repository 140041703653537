// MODULES
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// UTILS
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';

// SERVICES
import { UserService } from 'src/app/providers/user/user.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { UpdateService } from 'src/app/update.service';

declare var $: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {
  moduleList: any[] = [];
  modules: any[] = [];
  userData: any;
  isDirectMailFreeUser: boolean = false;

  constructor(
    private _userService: UserService,
    public _utilities: CommonFunctionsService,
    private router: Router,
    private _updateService: UpdateService,
  ) {}
  ngOnInit(): void {
    let token = localStorage.getItem('token');
    if (token) {
      this.getUserDetails();
    }
  }
  setMenu() {
    let manageUser = [];
    let tenDlc = [];
    if (this.userData?.isSubUser && this.userData?.isManageUser) {
      manageUser = [
        {
          id: 1,
          label: 'Manage Users',
          subText: 'Add/Manage Users - Permissions',
          link: '/users/user-list',
          icon: '/assets/images/sett-manage-user.svg',
        },
      ];
    } else if (!this.userData?.isSubUser) {
      manageUser = [
        {
          id: 1,
          label: 'Manage Users',
          subText: 'Add/Manage Users - Permissions',
          link: '/users/user-list',
          icon: '/assets/images/sett-manage-user.svg',
        },
      ];
      tenDlc = [
        {
          id: 3,
          label: 'A2P 10DLC',
          subText: 'Apply/Check 10DLC Status',
          link: '/manage-numbers/dlc-number',
          icon: '/assets/images/sett-a2p.svg',
        },
      ];
    }
    let settingMenu = [
      {
        label: 'General',
        submenu: [
          ...manageUser,
          {
            id: 2,
            label: 'Markets Management',
            subText: 'Add/Manage Markets',
            link: '/markets',
            icon: '/assets/images/sett-markets-management.svg',
          },
          ...tenDlc,
        ],
      },
      {
        label: 'Marketing',
        submenu: [
          {
            id: 4,
            label: 'Phone Numbers',
            subText: 'Buy/Manage Numbers',
            link: '/manage-numbers',
            icon: '/assets/images/sett-phone-numbers.svg',
          },
          {
            id: 5,
            label: 'Call Flow',
            subText: 'How incoming call should flow',
            link: '/manage-numbers/call-flow',
            icon: '/assets/images/sett-call-flow.svg',
          },
        ],
      },
      {
        label: 'Automation',
        submenu: [
          {
            id: 6,
            label: 'Drip Campaigns',
            subText: 'Add/Manage Drip Campaigns',
            link: '/drip-campaigns',
            icon: '/assets/images/sett-drip-campaigns.svg',
          },
          {
            id: 7,
            label: 'Global Tasks',
            subText: 'Add/Manage automated Tasks',
            link: '/global-tasks',
            icon: '/assets/images/sett-global-tasks.svg',
          },
        ],
      },
      {
        label: 'Customization',
        submenu: [
          {
            id: 8,
            label: 'Manage Templates',
            subText: 'SMS, Email, RVM, Task templates',
            link: '/manage-templates',
            icon: '/assets/images/sett-manage-templates.svg',
          },
          {
            id: 9,
            label: 'Custom Questions',
            subText: 'Leads, Buyers, Vendors, Dispo, Inv.',
            link: '/preference',
            icon: '/assets/images/sett-custom-questions.svg',
          },
          {
            id: 10,
            label: 'Web Forms',
            subText: 'Leads, Buyers, and Vendors',
            link: '/public/web-form',
            icon: '/assets/images/sett-sharable-web-forms.svg',
          },
          {
            id: 11,
            label: 'eSign Templates',
            subText: 'eSign Document Templates',
            link: '/esign/Template',
            icon: '/assets/images/sett-esign-templates.svg',
          },
        ],
      },
      {
        label: 'List Stacking',
        submenu: [
          {
            id: 12,
            label: 'Call Scripts',
            subText: 'Manage Cold Calling Scripts',
            link: '/dialer/script',
            icon: '/assets/images/sett-call-scripts.svg',
          },
          {
            id: 13,
            label: 'Dialer Voicemail',
            subText: 'Manage List Stacking Voicemail',
            link: '/dialer/voice-mail',
            icon: '/assets/images/sett-voice-mail-records.svg',
          },
        ],
      },
      {
        label: 'Other Settings',
        submenu: [
          {
            id: 14,
            label: 'Tags',
            subText: 'Manage Tags',
            link: '/tags',
            icon: '/assets/images/sett-tags.svg',
          },
          {
            id: 15,
            label: 'Web Hooks / API Token',
            subText: 'API Token for Zaiper integration',
            link: '/webhook-and-api-token',
            icon: '/assets/images/sett-api-token.svg',
          },
          {
            id: 16,
            label: 'General Files',
            subText: 'Central Storage of all the files',
            link: '/general-files',
            icon: '/assets/images/sett-general-files.svg',
          },
        ],
      },
    ];

    this.modules = settingMenu.filter((item) => item?.label);
  }
  getUserDetails() {
    this._userService.getUserDetails({}).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.userData = response?.data;

          if (this.userData?.isSubUser) {
            this.modules = this.moduleList.filter(
              (x) => x.id !== 2 && x.id !== 3 && x.id !== 4
            );
          } else {
            this.modules = this.moduleList;
          }
          if (this.userData.isDirectMailFreeUser == true) {
            this.isDirectMailFreeUser = true;
            localStorage.setItem(
              'isDirectMailFreeUser',
              this.userData.isDirectMailFreeUser
            );
          } else {
            this.setMenu();
          }
          this._utilities.userData = this.userData;
        }
      },
      (err: ErrorModel) => {}
    );
  }
  closePopOver(menu?) {
    $('.popover_close').parents('.popover').popover('hide');
    $('body .infinite-scroll').removeClass('re-scroll-hide');
    $('body .re-card').removeClass('re-open-card');

    if (!menu) return;

    if (menu?.link) {
      if (menu?.label == 'Call Flow') {
        this._utilities.currentNumberTab = 5;
      }
      this.router.navigate([menu?.link], {
        queryParams: menu?.queryParam,
      });
    } else if (menu?.externalLink) {
      window.open(menu?.externalLink, '_blank');
    } else {
      this._updateService.promptEvent.prompt();
    }
  }
  setttingsFilter(event) {
    this.modules.forEach((item) => {
      item?.submenu.forEach((val) => {
        if (
          val?.label
            ?.toLowerCase()
            .includes(event?.target?.value?.toLowerCase()) ||
          val?.subText
            ?.toLowerCase()
            .includes(event?.target?.value?.toLowerCase())
        ) {
          val.class = 're-search-link';
        } else {
          val.class = 're-not-search-link';
        }
      });
    });
  }
}
