<div
  class="re-plan-upgrade-main"
  *ngIf="data?.action == 'upgradeAction'"
  style="display: none"
>
  <!-- <span class="re-closebtn" matDialogClose mat-dialog-close>
    <img src="assets/images/close-grey.svg" alt="close" />
  </span> -->
  <p class="re-ptext">Do you want to</p>
  <h3 class="re-save-text">SAVE {{ percentage }}%</h3>
  <p class="re-ptext">with the Annual Plan?</p>
  <button type="button" class="btn btn-block re-savebtn" (click)="onYes()">
    YES, upgrade to get my {{ percentage }}% discount
  </button>
  <button type="button" class="btn btn-block re-cancelbtn" (click)="onNo()">
    NO, I prefer to pay full price
  </button>
</div>

<!--  *ngIf="data?.action == 'upgradeOfferAction'" -->
<div class="re-cyber-monday-main">
  <!--<img class="re-snowcap" src="assets/images/snow-cap.png" alt="snow" />
   <span class="re-closebtn" (click)="onNo()">
    <img src="assets/images/close-black-1.svg" alt="close" width="20" />
  </span> -->
  <h3 class="re-title-text">Labor Day Offer</h3>
  <p class="re-ptext-1">
    Upgrade & Save ${{ totalSaving }}<br />With The Yearly Plan!
  </p>
  <div class="re-timer-div">
    <div class="re-counts">
      <span class="re-number">{{ days }}</span>
      <span class="re-text">Days</span>
    </div>
    <span class="re-divide">:</span>
    <div class="re-counts">
      <span class="re-number">{{ hours }}</span>
      <span class="re-text">Hours</span>
    </div>
    <span class="re-divide">:</span>
    <div class="re-counts">
      <span class="re-number">{{ minutes }}</span>
      <span class="re-text">Minutes</span>
    </div>
    <span class="re-divide">:</span>
    <div class="re-counts">
      <span class="re-number">{{ seconds }}</span>
      <span class="re-text">Seconds</span>
    </div>
  </div>
  <!-- <p class="re-ptext-2">Offer expires on May 27, 2024.</p> -->
  <table class="table table-bordered text-left">
    <colgroup>
      <col width="50%" />
      <col width="50%" />
    </colgroup>
    <tbody>
      <tr>
        <th>Your Plan</th>
        <td>{{ planName }}</td>
      </tr>
      <tr>
        <th>Currently Paying</th>
        <td>${{ planPrice }}/mo</td>
      </tr>
      <tr>
        <th>Offer Price</th>
        <td>${{ offerPrice }}/mo</td>
      </tr>
      <tr>
        <th>Total Savings</th>
        <td>${{ totalSaving }}</td>
      </tr>
    </tbody>
  </table>
  <div class="re-btndiv">
    <button type="button" class="btn re-cancelbtn" (click)="onNo()">
      No, I’d Rather Pay Full Price
    </button>
    <button type="button" class="btn re-savebtn" (click)="onYes()">
      UPGRADE AND SAVE ${{ totalSaving }}
    </button>
  </div>
</div>

<div
  class="re-upgrade-now-main"
  *ngIf="data?.action == 'upgradeFirstMonthAction'"
>
  <span class="re-closebtn" (click)="onNo()">
    <img src="assets/images/close-grey.svg" alt="close" />
  </span>
  <h3 class="re-save-text">🎉 Congratulations on Your First Month! 🎉</h3>
  <p class="re-ptext">
    Upgrade to the Yearly plan now & save up to {{ percentage }}%!
  </p>
  <button type="button" class="btn re-savebtn" (click)="onYes()">
    Upgrade Now
  </button>
</div>
